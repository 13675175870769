<template>
  <section class="w-full pb-5 flex flex-col">
    <div class="vx-card__title mb-12">
      <h4 class="mb-4">Create account for free</h4>
      <p>Already have an account? <router-link to="/login">Sign In</router-link></p>
    </div>
    <!-- <div class="flex justify-center">
      <vs-button type="flat" color="primary"><span class="text-sm">Sign up with <strong>LinkedIn</strong></span></vs-button>
      <vs-button type="flat" color="danger"><span class="text-sm">Sign up with <strong>Google</strong></span></vs-button>
    </div>
    <vs-divider class="mb-base mt-5">or</vs-divider> -->
    <vs-alert color="success" v-if="success" class="mb-5 transform -translate-y-5">
      <p class="whitespace-pre-wrap text-left"
        ><strong>{{ success }}</strong></p
      >
    </vs-alert>
    <vs-alert color="danger" v-if="message" class="transform -translate-y-5">
      <p class="text-xs whitespace-pre-wrap text-left">{{ message }}</p>
    </vs-alert>
    <Form v-if="!success" class="text-left flex-1 flex flex-col" ref="form">
      <vs-button @click="signup" class="mb-base mt-6">Sign up</vs-button>
      <p class="text-xs mb-5 mt-auto"
        >By creating an account, you agree to our <a href="#" @click.prevent="$todo">Terms of service</a> and
        <a href="#" @click.prevent="$todo">Privacy policy</a> &copy; {{ $moment().year() }} - Flipmind. All Rights Reserved.</p
      >
    </Form>
  </section>
</template>

<script>
import Form from './Form';
import auth0 from '@/auth0';
export default {
  components: {
    Form
  },
  data: () => ({
    message: undefined,
    success: undefined
  }),
  methods: {
    async signup() {
      const $ctrl = this;
      const form = this.$refs.form;
      return await form.$validator.validateAll().then(valid => {
        if (valid) {
          auth0
            .signup(form.body)
            .then(result => {
              console.log(result);
              $ctrl.message = undefined;
              $ctrl.success = 'Success: Please check your email ' + form.body.email + ' for next steps';
            })
            .catch(e => {
              if (e.code === 'invalid_password') return ($ctrl.message = e.policy);
              if (e.code === 'invalid_signup') return ($ctrl.message = 'The email your are attempting to sign up is already registered or invalid.');
              $ctrl.message = e.error_description || e.description;
            });
        }
      });
    }
  },
  async beforeCreate() {
    await this.$auth0.handleAuthentication().catch(e => {
      if (e.error === 'unauthorized') return this.$router.push('/unauthorised');
    });
  }
};
</script>
